import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import { isNotBasicUser, isSysAdmin, isAdmin, isAdminOrSupervisor, isInterpreter } from "shared/utils";
import {
  APP_ASSIGNED_SESSIONS_URL,
  APP_HOME_URL,
  APP_ORGANIZATIONS_URL,
  APP_USERS_LIST_URL,
  APP_INTERPRETERS_LIST_URL,
  APP_MANAGE_CONFERENCES_URL,
  APP_INVITE_URL,
  APP_SYSTEM_USAGE_URL,
} from "shared/constants";

import styles from "./AppMenuFullscreen.module.css";

export default function AppMenuFullscreen({ disabled, className }) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);

  const classNameNavLink = classnames(styles.navLink, { inactiveLink: disabled });

  if (!user) {
    return null;
  }

  return (
    <nav className={classnames(styles.container, className)}>
      {isNotBasicUser(user) && !isSysAdmin(user) && (
        <NavLink className={classNameNavLink} exact to={APP_HOME_URL} id="nav_link_home">
          {t("nav__home")}
        </NavLink>
      )}
      {isAdminOrSupervisor(user) && (
        <NavLink
          className={classNameNavLink}
          exact={true}
          to={APP_MANAGE_CONFERENCES_URL}
          id="nav_link_manageConferences"
        >
          {t("nav__manageConferences")}
        </NavLink>
      )}
      {isSysAdmin(user) && (
        <NavLink className={styles.navLink} exact={true} to={APP_ORGANIZATIONS_URL} id="nav_link_organizations">
          {t("nav__organizations")}
        </NavLink>
      )}
      {isAdmin(user) && (
        <NavLink className={classNameNavLink} exact={true} to={APP_USERS_LIST_URL} id="nav_link_usersList">
          {t("nav__usersList")}
        </NavLink>
      )}
      {isAdminOrSupervisor(user) && (
        <NavLink
          className={classNameNavLink}
          exact={true}
          to={APP_INTERPRETERS_LIST_URL}
          id="nav_link_interpretersList"
        >
          {t("nav__interpretersList")}
        </NavLink>
      )}
      {isAdminOrSupervisor(user) && (
        <NavLink className={classNameNavLink} exact={true} to={APP_INVITE_URL} id="nav_link_invite">
          {t("nav__invite")}
        </NavLink>
      )}
      {isAdminOrSupervisor(user) && (
        <NavLink className={classNameNavLink} exact={true} to={APP_SYSTEM_USAGE_URL} id="nav_link_systemUsage">
          {t("nav__systemUsage")}
        </NavLink>
      )}
      {isInterpreter(user) && (
        <NavLink
          className={classNameNavLink}
          exact={true}
          to={APP_ASSIGNED_SESSIONS_URL}
          id="nav_link_assignedSessions"
        >
          {t("nav__assignedSessions")}
        </NavLink>
      )}
    </nav>
  );
}
