import { Suspense, lazy } from "react";

import Loading from "./Loading";

const HomeLazy = lazy(() => import("./Home"));

const Home = (props) => (
  <Suspense fallback={<Loading />}>
    <HomeLazy {...props} />
  </Suspense>
);

export default Home;
