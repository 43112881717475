import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { useAuth } from "shared/auth";
import AppHeader from "shared/components/AppHeader";
import {
  CONFERENCE_TOKEN_KEY,
  ADMIN,
  SYSADMIN,
  SUPERVISOR,
  INTERPRETER,
  EXTERNALINTERPRETER,
  BASIC_USER,
  APP_LOGIN_URL,
} from "shared/constants";

import GeneralProtectedRequests from "./GeneralProtectedRequests";

const defaultRoles = [ADMIN, SYSADMIN, SUPERVISOR, INTERPRETER, BASIC_USER, EXTERNALINTERPRETER];

export default function AuthenticatedRoute(props) {
  const { role = defaultRoles, children, ...restProps } = props;
  const roles = Array.isArray(role) ? role : [role];

  const { isAuthenticated, logout } = useAuth();
  const user = useSelector((state) => state.user);
  const hasRightRole = () => {
    if (!user) {
      return false;
    }

    return roles.includes(user.role);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      if (!localStorage.getItem(CONFERENCE_TOKEN_KEY)) {
        logout();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => window.removeEventListener("storage", handleStorageChange);
  }, [logout]);

  return (
    <Route
      {...restProps}
      render={({ location }) =>
        isAuthenticated() && hasRightRole() ? (
          <>
            <GeneralProtectedRequests />
            <AppHeader />
            {children}
          </>
        ) : (
          <Redirect to={{ pathname: APP_LOGIN_URL, state: { from: location } }} />
        )
      }
    />
  );
}
