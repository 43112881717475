import { Suspense, lazy } from "react";

import Loading from "./Loading";

const AssignedSessionsLazy = lazy(() => import("./AssignedSessions"));

const AssignedSessions = () => (
  <Suspense fallback={<Loading />}>
    <AssignedSessionsLazy />
  </Suspense>
);

export default AssignedSessions;
