import { Suspense, lazy } from "react";

import Loading from "./Loading";

const ForgotPasswordLazy = lazy(() => import("./ForgotPassword"));

const ForgotPassword = () => (
  <Suspense fallback={<Loading />}>
    <ForgotPasswordLazy />
  </Suspense>
);

export default ForgotPassword;
