import classnames from "classnames";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";

import { useAuth } from "shared/auth";
import {
  isNotBasicUser,
  isSysAdmin,
  isAdmin,
  isAdminOrSupervisor,
  isInterpreter,
  isExternalInterpreter,
} from "shared/utils";
import {
  APP_ASSIGNED_SESSIONS_URL,
  APP_HOME_URL,
  APP_ORGANIZATIONS_URL,
  APP_USERS_LIST_URL,
  APP_INTERPRETERS_LIST_URL,
  APP_MANAGE_CONFERENCES_URL,
  APP_PROFILE_URL,
  APP_INVITE_URL,
  APP_SYSTEM_USAGE_URL,
} from "shared/constants";
import Loading from "shared/components/Loading";

import styles from "./AppMenuResized.module.css";

export default function AppMenuResized({ disabled, organizations, changeOrganization }) {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const user = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);

  const openMenu = useCallback(() => setOpen(true), []);
  const closeMenu = useCallback(() => setOpen(false), []);

  const classNameNavLink = classnames(styles.navLink, { inactiveLink: disabled });

  if (!user) {
    return <Loading />;
  }

  return (
    <>
      <MenuIcon className={styles.menuIcon} onClick={openMenu} />

      <Drawer className={styles.menuDrawer} open={open} onClose={closeMenu}>
        <div className={styles.menuHeader}>
          <Typography className={styles.menuTitle}>{t("appMenuTitle")}</Typography>

          <CloseIcon className={styles.closeIcon} onClick={closeMenu} />
        </div>

        <nav className={styles.navbar}>
          {isNotBasicUser(user) && !isSysAdmin(user) && (
            <NavLink className={classNameNavLink} exact={true} to={APP_HOME_URL} onClick={closeMenu}>
              {t("nav__home")}
            </NavLink>
          )}
          {isAdminOrSupervisor(user) && (
            <NavLink className={classNameNavLink} exact={true} to={APP_MANAGE_CONFERENCES_URL} onClick={closeMenu}>
              {t("nav__manageConferences")}
            </NavLink>
          )}
          {isSysAdmin(user) && (
            <NavLink className={styles.navLink} exact={true} to={APP_ORGANIZATIONS_URL} onClick={closeMenu}>
              {t("nav__organizations")}
            </NavLink>
          )}
          {isAdmin(user) && (
            <NavLink className={classNameNavLink} exact={true} to={APP_USERS_LIST_URL} onClick={closeMenu}>
              {t("nav__usersList")}
            </NavLink>
          )}
          {isAdminOrSupervisor(user) && (
            <NavLink className={classNameNavLink} exact={true} to={APP_INTERPRETERS_LIST_URL} onClick={closeMenu}>
              {t("nav__interpretersList")}
            </NavLink>
          )}
          {isAdminOrSupervisor(user) && (
            <NavLink className={classNameNavLink} exact={true} to={APP_INVITE_URL} onClick={closeMenu}>
              {t("nav__invite")}
            </NavLink>
          )}
          {isAdminOrSupervisor(user) && (
            <NavLink className={classNameNavLink} exact={true} to={APP_SYSTEM_USAGE_URL} onClick={closeMenu}>
              {t("nav__systemUsage")}
            </NavLink>
          )}
          {isInterpreter(user) && (
            <NavLink className={classNameNavLink} exact={true} to={APP_ASSIGNED_SESSIONS_URL} onClick={closeMenu}>
              {t("nav__assignedSessions")}
            </NavLink>
          )}
          {!isExternalInterpreter(user) && (
            <NavLink className={classNameNavLink} exact={true} to={APP_PROFILE_URL} onClick={closeMenu}>
              {t("nav__profile")}
            </NavLink>
          )}

          <Typography className={styles.logout} onClick={logout}>
            {t("logout")}
          </Typography>

          {isInterpreter(user) && Array.isArray(organizations) ? (
            <select
              className={styles.select}
              defaultValue={organizations.find((org) => org.isActive === true).id}
              onChange={(e) => changeOrganization(parseInt(e.target.value), organizations)}
            >
              {organizations.map((org) => (
                <option key={org.id} value={org.id}>
                  {org.orgName}
                </option>
              ))}
            </select>
          ) : null}
        </nav>
      </Drawer>
    </>
  );
}
