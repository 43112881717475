import { SET_LISTENERS } from "state/actions/numberOfListeners";

const initialState = 0;

const numberOfListeners = (state = initialState, action) => {
  switch (action.type) {
    case SET_LISTENERS:
      return action.payload;
    default:
      return state;
  }
};

export default numberOfListeners;
