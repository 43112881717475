import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";

import {
  USER_KEY,
  CONFERENCE_TOKEN_KEY,
  APP_PROFILE_URL,
  APP_HOME_URL,
  APP_LOGIN_URL,
  LANGUAGE_KEY,
  getLoginURL,
  getRegisterURL,
  getForgotPasswordURL,
  getResetPasswordAnonymousURL,
  getValidateExternalInterpreterURL,
} from "shared/constants";
import { isBasicUser, isExternalInterpreter, isSysAdmin } from "shared/utils";
import { setUser } from "state/actions/user";
import axiosWrapper from "shared/services/axiosWrapper";
import { connectToAppHub, stopAppHub } from "shared/services/hubs";
import Loading from "shared/components/Loading";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [organizationAlias, setOrganizationAlias] = useState(null);
  const [isConnectedToAppHub, setIsConnectedToAppHub] = useState(false);
  const [weAreStillWaitingToGetTheUserData, setWeAreStillWaitingToGetTheUserData] = useState(true);

  const setOrganizationData = useCallback((token) => {
    const decoded = jwt_decode(token);

    localStorage.setItem(CONFERENCE_TOKEN_KEY, token);

    setOrganizationAlias(decoded.oa);
  }, []);

  const resetLoggedInData = useCallback(() => {
    dispatch(setUser(null));

    setOrganizationAlias(null);
    setIsConnectedToAppHub(false);
  }, [dispatch]);

  const setupHubConnection = useCallback(async (accessToken) => {
    try {
      const result = await connectToAppHub(accessToken);

      setIsConnectedToAppHub(result);
    } catch (e) {
      console.error("Error connecting to AppHub: ", e);
    }
  }, []);

  const login = useCallback(
    async ({ email, password, token }) => {
      try {
        let obj = {};

        if (token) {
          obj = {
            email,
            password,
            token,
          };
        } else {
          obj = {
            email,
            password,
          };
        }

        const result = await axiosWrapper({
          method: "post",
          url: getLoginURL(),
          data: JSON.stringify(obj),
          headers: {
            "Content-Type": "application/json",
          },
        });

        const user = result.data;

        await setupHubConnection(user.token);

        dispatch(setUser(user));

        if (user.appTranslationLanguageCode) {
          localStorage.setItem(LANGUAGE_KEY, user.appTranslationLanguageCode);
          i18n.changeLanguage(user.appTranslationLanguageCode);
          document.querySelector("html").lang = user.appTranslationLanguageCode;
        }

        if (isBasicUser(user) || isSysAdmin(user)) {
          history.push({ pathname: APP_PROFILE_URL });
        } else {
          history.push({ pathname: APP_HOME_URL });
        }

        return true;
      } catch (e) {
        console.error("Error on login: ", e);

        return {
          status: e.response.status,
          message: e.response.data,
        };
      }
    },
    [history, dispatch, setupHubConnection, i18n]
  );

  const loginForExternalInterpreter = useCallback(
    async ({ firstName, lastName, email, token }) => {
      try {
        const result = await axiosWrapper({
          method: "post",
          url: getValidateExternalInterpreterURL(),
          data: {
            email: email.toLowerCase(),
            firstName: firstName,
            lastName: lastName,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result && result.status === 200) {
          const user = result.data;

          await setupHubConnection(user.token);

          dispatch(setUser(user));

          if (user.appTranslationLanguageCode) {
            localStorage.setItem(LANGUAGE_KEY, user.appTranslationLanguageCode);
            i18n.changeLanguage(user.appTranslationLanguageCode);
            document.querySelector("html").lang = user.appTranslationLanguageCode;
          }

          history.push({ pathname: APP_HOME_URL });

          return true;
        }
      } catch (e) {
        console.error("Error on login: ", e);

        return {
          status: e.response.status,
          message: e.response.data,
        };
      }
    },
    [history, dispatch, setupHubConnection, i18n]
  );

  const logout = useCallback(async () => {
    setOrganizationAlias(null);

    if (isExternalInterpreter(user)) {
      localStorage.removeItem(CONFERENCE_TOKEN_KEY);
      localStorage.removeItem(USER_KEY);
      localStorage.removeItem(LANGUAGE_KEY);

      resetLoggedInData();

      history.push(APP_LOGIN_URL);
    } else {
      const logoutLink = user && user.links ? user.links.find((item) => item.rel === "logout") : null;

      if (logoutLink) {
        const { href, method, headers } = logoutLink;

        try {
          await axiosWrapper({
            url: href,
            method,
            headers,
          });

          localStorage.removeItem(CONFERENCE_TOKEN_KEY);
          localStorage.removeItem(USER_KEY);
          localStorage.removeItem(LANGUAGE_KEY);

          resetLoggedInData();

          history.push(APP_LOGIN_URL);

          stopAppHub();
        } catch (e) {
          console.error("Error on logout: ", e);
        }
      }
    }
  }, [history, user, resetLoggedInData]);

  const register = useCallback(
    async ({ firstName, lastName, email, password, token }) => {
      try {
        let obj = {};

        if (token) {
          obj = {
            firstName,
            lastName,
            email,
            password,
            token,
          };
        } else {
          obj = {
            firstName,
            lastName,
            email,
            password,
          };
        }

        const res = await axiosWrapper({
          method: "post",
          url: getRegisterURL(),
          data: JSON.stringify(obj),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if ((res.status = 200)) {
          login({ email, password });

          return true;
        }
      } catch (e) {
        console.error("Error on register: ", e);

        return e.response;
      }
    },
    [login]
  );

  const forgotPassword = useCallback(async (email) => {
    try {
      const res = await axiosWrapper({
        method: "post",
        url: getForgotPasswordURL(),
        data: JSON.stringify({
          userIdentifier: email,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if ((res.status = 200)) {
        return true;
      }
    } catch (e) {
      console.error("Error on forgot password: ", e);

      return e.response.data;
    }
  }, []);

  const resetPasswordAnon = useCallback(async ({ newPassword, confirmPassword, user, token }) => {
    try {
      const res = await axiosWrapper({
        method: "post",
        url: getResetPasswordAnonymousURL(),
        data: JSON.stringify({
          newPassword,
          confirmPassword,
          user,
          token,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if ((res.status = 200)) {
        return true;
      }
    } catch (e) {
      console.error("Error on reset password anon: ", e);

      return e.response.data;
    }
  }, []);

  const isAuthenticated = useCallback(() => {
    const token = localStorage.getItem(CONFERENCE_TOKEN_KEY);
    const userData = JSON.parse(localStorage.getItem(USER_KEY));

    if (token) {
      const decoded = jwt_decode(token);

      if (Date.now() >= decoded.exp * 1000) {
        return false;
      } else {
        if (!user) {
          dispatch(setUser(userData));
        }

        return true;
      }
    }

    return false;
  }, [dispatch, user]);

  const auth = useMemo(
    () => ({
      isConnectedToAppHub,
      organizationAlias,
      login,
      loginForExternalInterpreter,
      logout,
      register,
      forgotPassword,
      resetPasswordAnon,
      isAuthenticated,
      resetLoggedInData,
    }),
    [
      isConnectedToAppHub,
      organizationAlias,
      login,
      loginForExternalInterpreter,
      logout,
      register,
      forgotPassword,
      resetPasswordAnon,
      isAuthenticated,
      resetLoggedInData,
    ]
  );

  // COMPONENT LOGIC

  useEffect(() => {
    if (user) {
      localStorage.setItem(USER_KEY, JSON.stringify(user));

      if (user.token) {
        setOrganizationData(user.token);
      }
    }
  }, [user, setOrganizationData]);

  useEffect(() => {
    window.addEventListener("unload", async () => {
      if (isConnectedToAppHub) {
        await stopAppHub();
      }
    });
  }, [isConnectedToAppHub]);

  useEffect(() => {
    const checkAuth = async () => {
      const authenticated = isAuthenticated();

      if (user && authenticated && !isConnectedToAppHub) {
        await setupHubConnection(user.token);
      }

      setWeAreStillWaitingToGetTheUserData(false);
    };

    checkAuth();
  }, [user, isAuthenticated, isConnectedToAppHub, setupHubConnection]);

  if (weAreStillWaitingToGetTheUserData) {
    return <Loading />;
  }

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const value = useContext(AuthContext);

  if (!value) {
    throw new Error("`useAuth` should be used only inside `AuthProvider`");
  }

  return value;
};
