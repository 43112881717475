import { Suspense, lazy } from "react";

import Loading from "./Loading";

const InviteLazy = lazy(() => import("./Invite"));

const Invite = () => (
  <Suspense fallback={<Loading />}>
    <InviteLazy />
  </Suspense>
);

export default Invite;
