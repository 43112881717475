import { Suspense, lazy } from "react";

import Loading from "shared/components/Loading";

const InviteConfirmationLazy = lazy(() => import("./InviteConfirmation"));

const InviteConfirmation = () => (
  <Suspense fallback={<Loading />}>
    <InviteConfirmationLazy />
  </Suspense>
);

export default InviteConfirmation;
