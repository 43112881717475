import classnames from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";

import { useAuth } from "shared/auth";
import { APP_PROFILE_URL } from "shared/constants";
import Button from "shared/components/Button";
import { isExternalInterpreter, isInterpreter } from "shared/utils";

import styles from "./AppHeaderUser.module.css";

export default function AppHeaderUser({ disabled, organizations, changeOrganization }) {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const user = useSelector((state) => state.user);

  if (!user) {
    return null;
  }

  const roleName = user.roleName
    .replace(/^[a-z]/, (m) => m.toUpperCase())
    .replace(/([A-Z])/g, (m) => ` ${m}`)
    .trim();

  return (
    <div className={styles.container}>
      {isInterpreter(user) && Array.isArray(organizations) ? (
        <select
          className={styles.select}
          defaultValue={organizations.find((org) => org.isActive).id}
          onChange={(e) => changeOrganization(parseInt(e.target.value), organizations)}
        >
          {organizations.map((org) => (
            <option key={org.id} className={styles.option} value={org.id}>
              {org.orgName}
            </option>
          ))}
        </select>
      ) : null}

      <Link
        className={classnames(styles.avatarLink, { inactiveLink: disabled })}
        to={isExternalInterpreter(user) ? "#" : APP_PROFILE_URL}
        id="appHeader_link_myProfile"
      >
        <Avatar className="u-mr-full">
          <AccountCircle />
        </Avatar>

        <div>
          <Typography className={styles.userName}>{user.userName}</Typography>

          <Typography className={styles.userRole}>{roleName}</Typography>
        </div>
      </Link>

      <Button type="revoke" onClick={logout} id="button_logout">
        {t("logout")}
      </Button>
    </div>
  );
}
