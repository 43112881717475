import { Suspense, lazy } from "react";

import Loading from "./Loading";

const ConferenceManagementLazy = lazy(() => import("./App"));

export default function ConferenceManagement() {
  return (
    <Suspense fallback={<Loading />}>
      <ConferenceManagementLazy />
    </Suspense>
  );
}
