import { Skeleton } from "@material-ui/lab";

import styles from "./Loading.module.css";

const Loading = () => {
  return (
    <div className={styles.pageContainer}>
      <Skeleton variant="rect" animation="wave" className={styles.pageTitle} />
      <div className={styles.wrapper}>
        <Skeleton variant="rect" animation="wave" className={styles.createConference} />
        <Skeleton variant="rect" animation="wave" className={styles.text} />
      </div>
      <div className={styles.leftColumn}>
        <Skeleton variant="rect" animation="wave" className={styles.event} />
      </div>
    </div>
  );
};
export default Loading;
