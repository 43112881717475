import LogoPng from "shared/images/logo.png";


export default function Logo(props) {
  const { width = "auto", height = "auto" } = props;

  return (
    <img
      {...props}
      alt="Virtin"
      aria-hidden={true}
      height={height}
      src={LogoPng}
      width={width}
    />
  );
};
