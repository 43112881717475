import { Suspense, lazy } from "react";

import Loading from "./Loading";

const SpeakerDashboardLazy = lazy(() => import("./dashboard"));

const SpeakerDashboard = () => (
  <Suspense fallback={<Loading />}>
    <SpeakerDashboardLazy />
  </Suspense>
);

export default SpeakerDashboard;
