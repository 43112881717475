import VoxeetSDK2 from "shared/sdks/dolby";
import { session2Connect, session2Disconnect, session2IsLoading, session2LoadingStoped } from "./sessions";

export const VOXEET2_LOADING = "VOXEET2_LOADING";
export const VOXEET2_CONNECTED = "VOXEET2_CONNECTED";
export const VOXEET2_LEAVED = "VOXEET2_LEAVED";
export const VOXEET2_ERROR = "VOXEET2_ERROR";
export const VOXEET2_CLEAR_ERROR = "VOXEET2_CLEAR_ERROR";
export const VOXEET2_AUTOPLAY_BLOCKED_ENABLED = "VOXEET2_AUTOPLAY_BLOCKED_ENABLED";
export const VOXEET2_AUTOPLAY_BLOCKED_DISABLED = "VOXEET2_AUTOPLAY_BLOCKED_DISABLED";

let a = 0;

export const initializeAndOpenSession = (token, userInfo, refreshTokenCallback) => {
  return async (dispatch) => {
    if (!VoxeetSDK2.session.isOpen()) {
      dispatch(session2IsLoading());

      try {
        VoxeetSDK2.conference.on("autoplayBlocked", () => {
          dispatch(setAutoPlayBlockedEnabled());
        });

        await VoxeetSDK2.initializeToken(token, refreshTokenCallback);
        await VoxeetSDK2.session.open(userInfo);

        dispatch(session2Connect());
      } catch (e) {
        console.error(e);

        dispatch(session2LoadingStoped());
      }
    } else {
      dispatch(session2Connect());
    }
  };
};

export const createAndListenConference = (conferenceAlias) => {
  return async (dispatch) => {
    try {
      const dolbySpeakersConference = await VoxeetSDK2.conference.create({
        alias: conferenceAlias,
        params: {
          ttl: 0,
          dolbyVoice: true,
          liveRecording: true,
        },
      });
      await VoxeetSDK2.conference.listen(dolbySpeakersConference);

      a = 0;
      dispatch(connected());
    } catch (err) {
      if (err.message === "Conference does not exist" && a < 4) {
        a = a + 1;
        dispatch(this.createAndListenConference(conferenceAlias));
      } else {
        console.error("Error on joining SDK2 conference:", err);

        dispatch(onError(err));
      }
    }
  };
};

export const leaveConference = (shouldCloseSession = false) => {
  return async (dispatch) => {
    try {
      dispatch(isLoading());
      if (shouldCloseSession) {
        dispatch(session2IsLoading());
      }

      await VoxeetSDK2.conference.removeAllListeners();
      await VoxeetSDK2.conference.leave();

      if (shouldCloseSession) {
        await VoxeetSDK2.session.close();

        dispatch(session2Disconnect());
      }

      dispatch(leaved());
    } catch (e) {
      console.error("Error on leaving SDK2 conferance:", e);

      dispatch(onError(e));
      if (shouldCloseSession) {
        dispatch(session2LoadingStoped());
      }
    }
  };
};

export const leaveCreateAndListenConference = (conferenceAlias) => {
  return async (dispatch) => {
    try {
      dispatch(isLoading());

      await VoxeetSDK2.conference.removeAllListeners();
      await VoxeetSDK2.conference.leave();

      dispatch(leaved());
      dispatch(createAndListenConference(conferenceAlias));
    } catch (e) {
      console.error("Error on leaving SDK2  conferance:", e);

      dispatch(onError(e));
    }
  };
};

export const SDK2PlayBlockedAudio = () => {
  return async (dispatch) => {
    await VoxeetSDK2.conference.playBlockedAudio();

    dispatch(setAutoPlayBlockedDisabled());
  };
};

export const isLoading = () => ({
  type: VOXEET2_LOADING,
});

export const connected = () => ({
  type: VOXEET2_CONNECTED,
});

export const leaved = () => ({
  type: VOXEET2_LEAVED,
});

export const onError = (error) => ({
  type: VOXEET2_ERROR,
  payload: error,
});

export const clearError = () => ({
  type: VOXEET2_CLEAR_ERROR,
});

export const setAutoPlayBlockedEnabled = () => ({
  type: VOXEET2_AUTOPLAY_BLOCKED_ENABLED,
});

export const setAutoPlayBlockedDisabled = () => ({
  type: VOXEET2_AUTOPLAY_BLOCKED_DISABLED,
});
