export const SESSION_LOADING = "SESSION_LOADING";
export const SESSION_LOADING_STOPED = "SESSION_LOADING_STOPED";
export const SESSION_CONNECTED = "SESSION_CONNECTED";
export const SESSION_DISCONNECTED = "SESSION_DISCONNECTED";

export const SESSION2_LOADING = "SESSION2_LOADING";
export const SESSION2_LOADING_STOPED = "SESSION_LOADING2_STOPED";
export const SESSION2_CONNECTED = "SESSION2_CONNECTED";
export const SESSION2_DISCONNECTED = "SESSION2_DISCONNECTED";

export const sessionIsLoading = () => ({
  type: SESSION_LOADING,
});

export const sessionLoadingStoped = () => ({
  type: SESSION_LOADING_STOPED,
});

export const sessionConnect = () => ({
  type: SESSION_CONNECTED,
});

export const sessionDisconnect = () => ({
  type: SESSION_DISCONNECTED,
});

export const session2IsLoading = () => ({
  type: SESSION2_LOADING,
});

export const session2LoadingStoped = () => ({
  type: SESSION2_LOADING_STOPED,
});

export const session2Connect = () => ({
  type: SESSION2_CONNECTED,
});

export const session2Disconnect = () => ({
  type: SESSION2_DISCONNECTED,
});
