import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { VoxeetProvider } from "@voxeet/react-components";
import { StylesProvider } from "@material-ui/core/styles";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { storeApp } from "state/store";
import { AuthProvider } from "shared/auth";
import { SnackbarProvider } from "shared/providers/snackbar";
import { LANGUAGE_KEY } from "shared/constants";
import CustomThemeProvider from "shared/theme";

import App from "App";

import translation__cn from "translations/cn.json";
import translation__de from "translations/de.json";
import translation__en from "translations/en.json";
import translation__es from "translations/es.json";
import translation__fr from "translations/fr.json";
import translation__it from "translations/it.json";
import translation__nl from "translations/nl.json";
import translation__ro from "translations/ro.json";
import translation__tw from "translations/tw.json";
import translation__cz from "translations/cz.json";

import "index.css";

i18n.use(initReactI18next).init({
  resources: {
    cn: {
      translation: translation__cn,
    },
    de: {
      translation: translation__de,
    },
    gb: {
      translation: translation__en,
    },
    es: {
      translation: translation__es,
    },
    fr: {
      translation: translation__fr,
    },
    it: {
      translation: translation__it,
    },
    nl: {
      translation: translation__nl,
    },
    ro: {
      translation: translation__ro,
    },
    tw: {
      translation: translation__tw,
    },
    cz: {
      translation: translation__cz,
    },
  },
  lng: "gb",
  fallbackLng: "gb",
  interpolation: {
    escapeValue: false,
  },
});

const handleLanguageChange = () => {
  const storedLanguage = localStorage.getItem(LANGUAGE_KEY);

  if (storedLanguage) {
    i18n.changeLanguage(storedLanguage);
    document.querySelector("html").lang = storedLanguage;
  }
};

window.addEventListener("storage", handleLanguageChange);

handleLanguageChange();

ReactDOM.render(
  <CustomThemeProvider>
    <Provider store={storeApp}>
      <Router>
        <AuthProvider>
          <SnackbarProvider>
            <VoxeetProvider store={storeApp}>
              <StylesProvider injectFirst>
                <App />
              </StylesProvider>
            </VoxeetProvider>
          </SnackbarProvider>
        </AuthProvider>
      </Router>
    </Provider>
  </CustomThemeProvider>,
  document.getElementById("root")
);
