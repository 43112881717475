import { useCallback, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  APP_LOGIN_URL,
  APP_REGISTER_URL,
  APP_FORGOT_PASSWORD_URL,
  APP_INVITE_CONFIRMATION_URL,
  APP_HOME_URL,
  APP_PROFILE_URL,
  // APP_PENDING_REQUESTS_URL,
  APP_ASSIGNED_SESSIONS_URL,
  APP_MANAGE_CONFERENCES_URL,
  APP_ORGANIZATIONS_URL,
  APP_USERS_LIST_URL,
  APP_INVITE_URL,
  APP_SPEAKER_DASHBOARD_URL,
  APP_EXTERNALINTERPRETER_DASHBOARD_URL,
  APP_INTERPRETERS_LIST_URL,
  APP_UNAUTHENTICATED_URL,
  APP_FORBIDDEN_URL,
  ADMIN,
  SYSADMIN,
  SUPERVISOR,
  INTERPRETER,
  EXTERNALINTERPRETER,
  APP_SYSTEM_USAGE_URL,
} from "shared/constants";
import { useAuth } from "shared/auth";
import { joinOrganization, setupPanicHandlers, removePanicHandlers } from "shared/services/hubs";
import { useSnackbar } from "shared/providers/snackbar";
import { isSysAdminOrAdminOrSupervisor } from "shared/utils";

import AuthenticatedRoute from "shared/components/AuthenticatedRoute";
import Login from "pages/login";
import Register from "pages/register";
import ForgotPassword from "pages/forgotPassword";
import SpeakerDashboard from "pages/speakerDashboard";
import ExternalInterpreterDashboard from "pages/externalInterpreter";
import InviteConfirmation from "pages/inviteConfirmation";
import Home from "pages/home";
import Profile from "pages/profile";
// import PendingRequests from "pages/pendingRequests";
import AssignedSessions from "pages/assignedSessions";
import ConferenceManagement from "pages/conferenceManagement";
import Organizations from "pages/organizations";
import UsersList from "pages/usersList";
import InterpretersList from "pages/interpretersList";
import Invite from "pages/invite";
import Interpreter from "pages/interpreter";
import Unauthenticated from "pages/unauthenticated";
import Forbidden from "pages/forbidden";
import NotFound from "pages/notFound";
import RecordingList from "pages/recordingList";

const App = () => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbar();
  const user = useSelector((state) => state.user);
  const { isConnectedToAppHub, organizationAlias } = useAuth();
  const [panicPayload, setPanicPayload] = useState(null);
  const shouldReceivePanicNotifications = isSysAdminOrAdminOrSupervisor(user);

  const onPanicEnabled = useCallback(
    (payload) => {
      if (shouldReceivePanicNotifications) {
        showMessage(
          t("home__panicEnabledSnackbar", {
            session: payload.sessionName,
          })
        );
      }

      setPanicPayload({ ...payload, panicMode: true });
    },
    [shouldReceivePanicNotifications, showMessage, t]
  );

  const onPanicDisabled = useCallback(
    (payload) => {
      if (shouldReceivePanicNotifications) {
        showMessage(
          t("home__panicDisabledSnackbar", {
            session: payload.sessionName,
          })
        );
      }

      setPanicPayload({ ...payload, panicMode: false });
    },
    [shouldReceivePanicNotifications, showMessage, t]
  );

  useEffect(() => {
    const setup = async () => {
      if (isConnectedToAppHub && organizationAlias) {
        await joinOrganization(organizationAlias);

        setupPanicHandlers(onPanicEnabled, onPanicDisabled);
      }
    };

    setup();

    return () => {
      removePanicHandlers(onPanicEnabled, onPanicDisabled);
    };
  }, [isConnectedToAppHub, organizationAlias, shouldReceivePanicNotifications, onPanicEnabled, onPanicDisabled]);

  return (
    <div className="u-fx u-fw u-fh">
      <div
        className="u-fx u-fx-column"
        style={{
          width: "100%",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <Switch>
          <Route exact path={APP_LOGIN_URL}>
            <Login />
          </Route>
          <Route exact path={APP_REGISTER_URL}>
            <Register />
          </Route>
          <Route exact path={APP_FORGOT_PASSWORD_URL}>
            <ForgotPassword />
          </Route>
          <Route exact path={APP_INVITE_CONFIRMATION_URL}>
            <InviteConfirmation />
          </Route>
          <Route exact path={APP_SPEAKER_DASHBOARD_URL}>
            <SpeakerDashboard />
          </Route>
          <Route exact path={APP_EXTERNALINTERPRETER_DASHBOARD_URL}>
            <ExternalInterpreterDashboard />
          </Route>
          <Route exact path={APP_UNAUTHENTICATED_URL}>
            <Unauthenticated />
          </Route>
          <Route exact path={APP_FORBIDDEN_URL}>
            <Forbidden />
          </Route>
          {/* ALL ROLES */}
          <AuthenticatedRoute exact path={APP_PROFILE_URL}>
            <Profile />
          </AuthenticatedRoute>

          {/* SYSADMIN */}
          <AuthenticatedRoute exact role={[SYSADMIN]} path={APP_ORGANIZATIONS_URL}>
            <Organizations />
          </AuthenticatedRoute>
          {/* ADMIN */}
          {/* <AuthenticatedRoute
                        exact
                        role={[ADMIN]}
                        path={APP_PENDING_REQUESTS_URL}
                    >
                        <PendingRequests />
                    </AuthenticatedRoute> */}
          <AuthenticatedRoute exact role={[ADMIN]} path={APP_USERS_LIST_URL}>
            <UsersList />
          </AuthenticatedRoute>
          {/* MIXED ROLES */}
          <AuthenticatedRoute exact role={[ADMIN, SUPERVISOR, INTERPRETER, EXTERNALINTERPRETER]} path={APP_HOME_URL}>
            <Home panicPayload={panicPayload} />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact role={[ADMIN, SUPERVISOR]} path={APP_MANAGE_CONFERENCES_URL}>
            <ConferenceManagement />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact role={[ADMIN, SUPERVISOR]} path={APP_INTERPRETERS_LIST_URL}>
            <InterpretersList />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact role={[ADMIN, SUPERVISOR]} path={`${APP_INTERPRETERS_LIST_URL}/:alias`}>
            <Interpreter />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact role={[ADMIN, SUPERVISOR]} path={APP_INVITE_URL}>
            <Invite />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact role={[ADMIN, SUPERVISOR]} path={APP_SYSTEM_USAGE_URL}>
            <RecordingList />
          </AuthenticatedRoute>
          {/* INTERPRETER */}
          <AuthenticatedRoute exact role={INTERPRETER} path={APP_ASSIGNED_SESSIONS_URL}>
            <AssignedSessions />
          </AuthenticatedRoute>
          {/* EVERYTHING ELSE */}
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default App;
