import { Suspense, lazy } from "react";

import Loading from "./Loading";

const UsersListLazy = lazy(() => import("./UsersList"));

const UsersList = () => (
  <Suspense fallback={<Loading />}>
    <UsersListLazy />
  </Suspense>
);

export default UsersList;
