import classnames from "classnames";
import MuiButton from "@material-ui/core/Button";

import styles from "./Button.module.css";


export default function Button({ type, ...props }) {
  const buttonType = type === "revoke" ? "button" : type;

  const className = [styles.button, props.className, {
    [styles.revoke]: type === "revoke"
  }];

  return (
    <MuiButton
      {...props}
      className={classnames(className)}
      type={buttonType}
    />
  );
};
