import { Suspense, lazy } from "react";

import Loading from "shared/components/Loading";

const UnauthenticatedLazy = lazy(() => import("./Unauthenticated"));

const Unauthenticated = () => (
  <Suspense fallback={<Loading />}>
    <UnauthenticatedLazy />
  </Suspense>
);

export default Unauthenticated;
