import { SET_PROFILE } from "state/actions/profile";

const initialState = null;

const profile = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE:
      return action.payload;
    default:
      return state;
  }
};

export default profile;
