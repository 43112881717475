import React, { useCallback, useMemo, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const SnackbarContext = React.createContext(null);

export const useSnackbar = () => {
  const value = React.useContext(SnackbarContext);

  if (!value) {
    throw new Error("`useSnackbar` should be used only inside `SnackbarProvider`");
  }

  return value;
};

export const SnackbarProvider = ({ children }) => {
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const showMessage = useCallback((message, type = "success") => {
    setSnackbarState({
      open: true,
      message,
      type,
    });
  }, []);

  const handleClose = () => {
    setSnackbarState({
      open: false,
      message: "",
      type: "success",
    });
  };

  const contextValue = useMemo(
    () => ({
      showMessage,
    }),
    [showMessage]
  );

  return (
    <>
      <SnackbarContext.Provider value={contextValue}>{children}</SnackbarContext.Provider>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snackbarState.open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert variant="filled" severity={snackbarState.type}>
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </>
  );
};
