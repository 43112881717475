import { Suspense, lazy } from "react";

import Loading from "./Loading";

const RegisterLazy = lazy(() => import("./Register"));

const Register = () => (
  <Suspense fallback={<Loading />}>
    <RegisterLazy />
  </Suspense>
);

export default Register;
