import { Skeleton } from "@material-ui/lab";
import styles from "./Loading.module.css";

const Loading = () => {
  return (
    <div className={styles.container}>
      <Skeleton variant="rect" animation="wave" className={styles.title} />
      <Skeleton variant="rect" animation="wave" className={styles.goBack} />

      <div className={styles.wrapper}>
        <Skeleton variant="circle" animation="wave" className={styles.icon} />
        <Skeleton variant="rect" animation="wave" className={styles.name} />
      </div>

      <Skeleton variant="rect" animation="wave" className={styles.sectionName} />

      <div className={styles.fieldsWrapper}>
        <div>
          <Skeleton variant="rect" animation="wave" className={styles.field} width={"4rem"} />
        </div>
        <Skeleton variant="rect" animation="wave" className={styles.field} width={"10rem"} />
      </div>
      <div className={styles.fieldsWrapper}>
        <div>
          <Skeleton variant="rect" animation="wave" className={styles.field} width={"6rem"} />
        </div>
        <Skeleton variant="rect" animation="wave" className={styles.field} width={"15rem"} />
      </div>
      <div className={styles.fieldsWrapper}>
        <div>
          <Skeleton variant="rect" animation="wave" className={styles.field} width={"5rem"} />
        </div>
        <Skeleton variant="rect" animation="wave" className={styles.field} width={"14rem"} />
      </div>
      <div className={styles.fieldsWrapper}>
        <div>
          <Skeleton variant="rect" animation="wave" className={styles.field} width={"8rem"} />
        </div>
        <Skeleton variant="rect" animation="wave" className={styles.field} width={"17rem"} />
      </div>
      <div className={styles.fieldsWrapper}>
        <div>
          <Skeleton variant="rect" animation="wave" className={styles.field} width={"5rem"} />
        </div>
        <Skeleton variant="rect" animation="wave" className={styles.field} width={"14rem"} />
      </div>
      <div className={styles.fieldsWrapper}>
        <div>
          <Skeleton variant="rect" animation="wave" className={styles.field} width={"6rem"} />
        </div>
        <Skeleton variant="rect" animation="wave" className={styles.field} width={"17rem"} />
      </div>
      <div className={styles.fieldsWrapper}>
        <div>
          <Skeleton variant="rect" animation="wave" className={styles.field} width={"8rem"} />
        </div>
        <Skeleton variant="rect" animation="wave" className={styles.field} width={"20rem"} />
      </div>
    </div>
  );
};

export default Loading;
