import { Suspense, lazy } from "react";

import Loading from "./Loading";

const LoginLazy = lazy(() => import("./Login"));

const Login = () => (
  <Suspense fallback={<Loading />}>
    <LoginLazy />
  </Suspense>
);

export default Login;
