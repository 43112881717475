import { Skeleton } from "@material-ui/lab";
import styles from "./Loading.module.css";

export default function Loading() {
  return (
    <div className={styles.container}>
      <Skeleton variant="rect" animation="wave" className={styles.title} />

      <Skeleton variant="rect" animation="wave" className={styles.fieldSet} />

      <Skeleton variant="rect" animation="wave" className={styles.button} />

      <Skeleton variant="rect" animation="wave" className={styles.goBack} />
    </div>
  );
}
