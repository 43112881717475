import {
  VOXEET2_LOADING,
  VOXEET2_CONNECTED,
  VOXEET2_ERROR,
  VOXEET2_LEAVED,
  VOXEET2_CLEAR_ERROR,
  VOXEET2_AUTOPLAY_BLOCKED_ENABLED,
  VOXEET2_AUTOPLAY_BLOCKED_DISABLED,
} from "state/actions/voxeet2";

const initialState = {
  loading: false,
  isConnected: false,
  error: null,
  sdk2autoPlayBlocked: false,
};

const voxeet2 = (state = initialState, action) => {
  switch (action.type) {
    case VOXEET2_LOADING:
      return {
        ...state,
        loading: true,
      };

    case VOXEET2_CONNECTED:
      return {
        ...state,
        loading: false,
        isConnected: true,
      };

    case VOXEET2_LEAVED:
      return {
        ...state,
        loading: false,
        isConnected: false,
      };

    case VOXEET2_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case VOXEET2_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    case VOXEET2_AUTOPLAY_BLOCKED_ENABLED:
      return {
        ...state,
        sdk2autoPlayBlocked: true,
      };

    case VOXEET2_AUTOPLAY_BLOCKED_DISABLED:
      return {
        ...state,
        sdk2autoPlayBlocked: false,
      };

    default:
      return state;
  }
};

export default voxeet2;
