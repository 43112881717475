import CircularProgress from "@material-ui/core/CircularProgress";

import styles from "./Loading.module.css";

export default function Loading() {
  return (
    <div className={styles.container}>
      <CircularProgress size={"4rem"} className={styles.loadingWheel} />
    </div>
  );
}
