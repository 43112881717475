import { Suspense, lazy } from "react";

import Loading from "./Loading";

const InterpretersListLazy = lazy(() => import("./InterpretersList"));

const InterpretersList = () => (
  <Suspense fallback={<Loading />}>
    <InterpretersListLazy />
  </Suspense>
);

export default InterpretersList;
