import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { reducer as voxeetReducer } from "@voxeet/react-components";
import user from "state/reducers/user";
import profile from "state/reducers/profile";
import languages from "state/reducers/languages";
import roles from "state/reducers/roles";
import voxeet2 from "state/reducers/voxeet2";
import sessions from "state/reducers/sessions";
import numberOfListeners from "state/reducers/numberOfListeners";

const rootReducerApp = combineReducers({
  voxeet: voxeetReducer,
  user,
  profile,
  languages,
  roles,
  voxeet2,
  sessions,
  numberOfListeners,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const storeApp = createStore(rootReducerApp, composeEnhancer(applyMiddleware(thunkMiddleware)));
