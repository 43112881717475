import { format, isSameDay, isBefore } from "date-fns";

import {
  ADMIN,
  INTERPRETER,
  EXTERNALINTERPRETER,
  SYSADMIN,
  SUPERVISOR,
  BASIC_USER,
  ALLOWED_FILE_EXTENSIONS_FOR_PROFILE,
} from "shared/constants";

// USER

export const isSysAdmin = (user) => user && user.role === SYSADMIN;

export const isAdmin = (user) => user && user.role === ADMIN;

export const isSysAdminOrAdmin = (user) => user && (user.role === SYSADMIN || user.role === ADMIN);

export const isSupervisor = (user) => user && user.role === SUPERVISOR;

export const isInterpreter = (user) => user && user.role === INTERPRETER;

export const isExternalInterpreter = (user) => user && user.role === EXTERNALINTERPRETER;

export const isStandardOrExternalInterpreter = (user) => isInterpreter(user) || isExternalInterpreter(user);

export const isSysAdminOrAdminOrSupervisor = (user) => isSysAdminOrAdmin(user) || isSupervisor(user);

export const isAdminOrSupervisor = (user) => isAdmin(user) || isSupervisor(user);

export const isNotBasicUser = (user) => user && user.role !== BASIC_USER;

export const isBasicUser = (user) => user && user.role === BASIC_USER;

// OTHER

export const getVideoDeviceName = ({ currentVideoDevice }) => {
  if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
    return navigator.mediaDevices.enumerateDevices().then(function (sources) {
      sources.forEach((source) => {
        if (source.kind === "videoinput" && source.deviceId === currentVideoDevice) {
          if (source.facingMode === "environment" || source.label.indexOf("facing back") >= 0) {
            return Promise.resolve(true);
          }
        }
      });

      return Promise.resolve(false);
    });
  }
};

export const determineEventDuration = (startDate, endDate, dateFormat) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (isSameDay(start, end)) {
    return `${format(start, dateFormat)}`;
  }

  return `${format(start, dateFormat)} - ${format(end, dateFormat)}`;
};

export const getNameInitials = (name) =>
  name
    .split(" ")
    .map((el) => el.charAt(0))
    .join("");

export const showTimer = (timerEndDate) => timerEndDate && isBefore(new Date(), new Date(timerEndDate));

export const validateExtension = (value, required) => {
  if (value === true) {
    return true;
  }

  if (!value) {
    return !required;
  }

  const index = value.name.lastIndexOf(".");
  const extension = value.name.substr(index + 1);

  return ALLOWED_FILE_EXTENSIONS_FOR_PROFILE.includes(extension.toLowerCase());
};

export const addStreamNode = (stream, container, userId) => {
  let videoNode = container.querySelector("video");

  if (!videoNode) {
    videoNode = document.createElement("video");

    videoNode.setAttribute("playsinline", true);
    videoNode.muted = true;
    videoNode.setAttribute("autoplay", "autoplay");

    container.appendChild(videoNode);
  }

  navigator.attachMediaStream(videoNode, stream);

  if (userId) {
    container.setAttribute("data-user-id", userId);
  }
};

export const removeStreamNode = (container) => {
  let videoNode = container.querySelector("video");

  if (videoNode) {
    videoNode.srcObject = null;

    container.removeChild(videoNode);

    if (container.getAttribute("data-user-id")) {
      container.removeAttribute("data-user-id");
    }
  }
};

export const setAudioVolume = (element, volume) => (element.volume = volume);

export const queryDOMForAudioElement = (cssSelector, successCb, tries = 1) => {
  if (tries < 5) {
    const audioDOMElement = document.querySelector(cssSelector);

    if (audioDOMElement) {
      if (successCb && typeof successCb === "function") {
        successCb(audioDOMElement);
      }
    } else {
      setTimeout(() => queryDOMForAudioElement(cssSelector, successCb, tries + 1), 1000);
    }
  }
};

export const resolveLanguageName = (t, langCode, langName) => {
  return t(`language_name_${langCode}`) !== `language_name_${langCode}` ? t(`language_name_${langCode}`) : langName;
};

// export const isSafari = () => {
//   return (
//     navigator.vendor &&
//     navigator.vendor.indexOf("Apple") > -1 &&
//     navigator.userAgent &&
//     navigator.userAgent.indexOf("CriOS") === -1 &&
//     navigator.userAgent.indexOf("FxiOS") === -1
//   );
// };

export function openFullscreen(elem) {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    elem.msRequestFullscreen();
  }
}

export function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    /* Safari */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE11 */
    document.msExitFullscreen();
  }
}
