import { Suspense, lazy } from "react";

import Loading from "./Loading";

const OrganizationsLazy = lazy(() => import("./Organizations"));

const Organizations = () => (
  <Suspense fallback={<Loading />}>
    <OrganizationsLazy />
  </Suspense>
);

export default Organizations;
