import {
  SESSION_LOADING,
  SESSION_LOADING_STOPED,
  SESSION_CONNECTED,
  SESSION_DISCONNECTED,
  SESSION2_LOADING,
  SESSION2_LOADING_STOPED,
  SESSION2_CONNECTED,
  SESSION2_DISCONNECTED,
} from "state/actions/sessions";

const initialState = {
  sessionLoading: false,
  sessionConnected: false,
  session2Loading: false,
  session2Connected: false,
};

const sessions = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_LOADING:
      return {
        ...state,
        sessionLoading: true,
      };

    case SESSION_LOADING_STOPED:
      return {
        ...state,
        sessionLoading: false,
      };

    case SESSION_CONNECTED:
      return {
        ...state,
        sessionLoading: false,
        sessionConnected: true,
      };

    case SESSION_DISCONNECTED:
      return {
        ...state,
        sessionLoading: false,
        sessionConnected: false,
      };

    case SESSION2_LOADING:
      return {
        ...state,
        session2Loading: true,
      };

    case SESSION2_LOADING_STOPED:
      return {
        ...state,
        session2Loading: false,
      };

    case SESSION2_CONNECTED:
      return {
        ...state,
        session2Loading: false,
        session2Connected: true,
      };

    case SESSION2_DISCONNECTED:
      return {
        ...state,
        session2Loading: false,
        session2Connected: false,
      };

    default:
      return state;
  }
};

export default sessions;
