import { Skeleton } from "@material-ui/lab";
import styles from "./Loading.module.css";

const Loading = () => {
  return (
    <div className={styles.container}>
      <Skeleton variant="rect" animation="wave" className={styles.title} />

      <div className={styles.wrapper}>
        <Skeleton variant="circle" animation="wave" className={styles.icon} />
        <Skeleton variant="rect" animation="wave" className={styles.name} />
      </div>

      <Skeleton variant="rect" animation="wave" className={styles.sectionName} width={"10rem"} />
      <div className={styles.fieldsWrapper}>
        <div>
          <Skeleton variant="rect" animation="wave" className={styles.field} width={"4rem"} />
        </div>
        <Skeleton variant="rect" animation="wave" className={styles.field} width={"100%"} />
      </div>
      <div className={styles.fieldsWrapper}>
        <div>
          <Skeleton variant="rect" animation="wave" className={styles.field} width={"6rem"} />
        </div>
        <Skeleton variant="rect" animation="wave" className={styles.field} width={"100%"} />
      </div>

      <div className={styles.fieldsWrapper}>
        <div>
          <Skeleton variant="rect" animation="wave" className={styles.field} width={"5rem"} />
        </div>
        <Skeleton variant="rect" animation="wave" className={styles.field} width={"100%"} />
      </div>
      <div className={styles.fieldsWrapper}>
        <div>
          <Skeleton variant="rect" animation="wave" className={styles.field} width={"6rem"} />
        </div>
        <Skeleton variant="rect" animation="wave" className={styles.field} width={"100%"} />
      </div>
      <div className={styles.fieldsWrapper}>
        <Skeleton variant="rect" animation="wave" className={styles.field} width={"100%"} />
      </div>

      <div className={styles.devider}></div>

      <Skeleton variant="rect" animation="wave" className={styles.sectionName} width={"6rem"} />
      <div className={styles.fieldsWrapper}>
        <div>
          <Skeleton variant="rect" animation="wave" className={styles.field} width={"4rem"} />
        </div>
        <Skeleton variant="rect" animation="wave" className={styles.field} width={"100%"} />
      </div>
      <div className={styles.fieldsWrapper}>
        <div>
          <Skeleton variant="rect" animation="wave" className={styles.field} width={"6rem"} />
        </div>
        <Skeleton variant="rect" animation="wave" className={styles.field} width={"100%"} />
      </div>

      <div className={styles.fieldsWrapper}>
        <div>
          <Skeleton variant="rect" animation="wave" className={styles.field} width={"5rem"} />
        </div>
        <Skeleton variant="rect" animation="wave" className={styles.field} width={"100%"} />
      </div>
      <div className={styles.fieldsWrapper}>
        <div>
          <Skeleton variant="rect" animation="wave" className={styles.field} width={"6rem"} />
        </div>
        <Skeleton variant="rect" animation="wave" className={styles.field} width={"100%"} />
      </div>
      <div className={styles.fieldsWrapper}>
        <Skeleton variant="rect" animation="wave" className={styles.field} width={"100%"} />
      </div>
    </div>
  );
};

export default Loading;
