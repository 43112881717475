import { Suspense, lazy } from "react";

import Loading from "./Loading";

const InterpreterLazy = lazy(() => import("./Interpreter"));

const Interpreter = () => (
  <Suspense fallback={<Loading />}>
    <InterpreterLazy />
  </Suspense>
);

export default Interpreter;
