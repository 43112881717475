import { Suspense, lazy } from "react";

import Loading from "./Loading";

const RecordingListLazy = lazy(() => import("./RecordingList"));

const Recording = () => (
  <Suspense fallback={<Loading />}>
    <RecordingListLazy />
  </Suspense>
);

export default Recording;
