import { Skeleton } from "@material-ui/lab";
import styles from "./Loading.module.css";

const Loading = () => {
  return (
    <div className={styles.container}>
      <Skeleton variant="rect" animation="wave" className={styles.title} />

      <Skeleton variant="rect" animation="wave" className={styles.field} />
      <Skeleton variant="rect" animation="wave" className={styles.field} />
      <Skeleton variant="rect" animation="wave" className={styles.field} />

      <Skeleton variant="rect" animation="wave" className={styles.button} />
    </div>
  );
};

export default Loading;
