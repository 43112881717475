import { Suspense, lazy } from "react";

import Loading from "shared/components/Loading";

const NotFoundLazy = lazy(() => import("./NotFound"));

const NotFound = () => (
  <Suspense fallback={<Loading />}>
    <NotFoundLazy />
  </Suspense>
);

export default NotFound;
