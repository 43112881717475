import classname from "classnames";
import { useEffect, useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Toolbar from "@material-ui/core/Toolbar";

import { APP_HOME_URL, CONFERENCE_TOKEN_KEY, USER_KEY } from "shared/constants";
import Logo from "shared/components/Logo";
import AppMenuFullscreen from "shared/components/AppMenuFullscreen";
import AppMenuResized from "shared/components/AppMenuResized";
import AppHeaderUser from "shared/components/AppHeaderUser";
import axiosWrapper from "shared/services/axiosWrapper";
import { isInterpreter } from "shared/utils";

import styles from "./AppHeader.module.css";

export default function AppHeader() {
  const user = useSelector((state) => state.user);
  const under1200 = useMediaQuery("(max-width: 1200px)");
  const [organizations, setOrganizations] = useState(null);
  const { sessionLoading, session2Loading } = useSelector((state) => state.sessions);
  const { connecting } = useSelector((state) => state.voxeet.conference);
  const { loading } = useSelector((state) => state.voxeet2);
  const location = useLocation();

  const disabled = sessionLoading || session2Loading || connecting || loading;

  const changeOrganization = useCallback(
    async (id, organizations) => {
      const organization = organizations.find((org) => org.id === id);
      const request = organization.links.find((item) => item.rel === "set_account_organization");

      if (request) {
        try {
          const { href, method, headers } = request;

          const result = await axiosWrapper({
            url: href,
            method,
            headers,
          });
          const localUser = { ...user, token: result.data.token };

          setOrganizations(result.data.organizations);
          localStorage.setItem(CONFERENCE_TOKEN_KEY, result.data.token);
          localStorage.setItem(USER_KEY, JSON.stringify(localUser));

          window.location.reload();
        } catch (e) {
          console.error("Error on get token: ", e);
        }
      }
    },
    [user]
  );

  useEffect(() => {
    const getOrganizations = async () => {
      const request = user.links.find((item) => item.rel === "get_account_organizations");

      if (request) {
        try {
          const { href, method, headers } = request;

          const result = await axiosWrapper({
            url: href,
            method,
            headers,
          });

          setOrganizations(result.data);

          const userToken = jwt_decode(user.token);
          const activeOrganization = result.data.find((org) => org.isActive === true);

          if (activeOrganization.id !== parseInt(userToken.organizationId)) {
            changeOrganization(activeOrganization.id, result.data);
          }
        } catch (e) {
          console.error("Error on get organization: ", e);
        }
      }
    };

    if (isInterpreter(user)) {
      getOrganizations();
    }
  }, [user, changeOrganization]);

  const toolbarClassName = classname(styles.toolbar, {
    [styles.minWidth]: location.pathname === APP_HOME_URL && !isInterpreter(user),
  });

  if (under1200) {
    return (
      <Toolbar className={toolbarClassName}>
        <AppMenuResized disabled={disabled} organizations={organizations} changeOrganization={changeOrganization} />

        <Link to={APP_HOME_URL}>
          <Logo className="u-ml-full" width="100" />
        </Link>
      </Toolbar>
    );
  }

  return (
    <Toolbar className={styles.toolbar}>
      <Link to={APP_HOME_URL}>
        <Logo width="100" />
      </Link>

      <div className="u-fx ufx-align-center">
        <AppMenuFullscreen disabled={disabled} className="u-mr-full" />
        <AppHeaderUser disabled={disabled} organizations={organizations} changeOrganization={changeOrganization} />
      </div>
    </Toolbar>
  );
}
