import { HubConnectionBuilder } from "@microsoft/signalr";
import { CHAT_EVENTS, SESSION_EVENTS, VIRTUAL_CABIN_EVENTS, getHubURL } from "shared/constants";
import axiosWrapper from "shared/services/axiosWrapper";

let connection = null;

export const connectToAppHub = async (accessToken) => {
  if (!connection) {
    connection = new HubConnectionBuilder()
      .withUrl(getHubURL(), { accessTokenFactory: () => accessToken })
      .withAutomaticReconnect()
      .build();

    try {
      await connection.start();

      return true;
    } catch (e) {
      console.error("Failed to connect to hub: ", e);
    }

    return false;
  }

  return false;
};

export const stopAppHub = async () => {
  if (connection) {
    await connection.stop();

    connection = null;
  }
};

export const listenEventsForSession = async (sessionAlias, joinChat) => {
  try {
    await connection.invoke(SESSION_EVENTS.JoinSession, sessionAlias);
    joinChat && (await connection.invoke(CHAT_EVENTS.JoinChat, sessionAlias));
  } catch (e) {
    console.error("Error on listening events for session: ", e);
  }
};

export const setupChatEventHandlers = (onMessageReceived) => {
  connection.on(CHAT_EVENTS.ReceiveMessage, onMessageReceived);
};

export const removeChatEventHandlers = async (onMessageReceived) => {
  connection.off(CHAT_EVENTS.ReceiveMessage, onMessageReceived);
};

export const setupPanicHandlers = (onPanicEnabled, onPanicDisabled) => {
  connection.on(VIRTUAL_CABIN_EVENTS.PanicEnabled, onPanicEnabled);
  connection.on(VIRTUAL_CABIN_EVENTS.PanicDisabled, onPanicDisabled);
};

export const removePanicHandlers = async (onPanicEnabled, onPanicDisabled) => {
  connection.off(VIRTUAL_CABIN_EVENTS.PanicEnabled, onPanicEnabled);
  connection.off(VIRTUAL_CABIN_EVENTS.PanicDisabled, onPanicDisabled);
};

export const setupLanguageSwitchHandlers = (onLanguageSwitched) => {
  connection.on(VIRTUAL_CABIN_EVENTS.LanguageSwitched, onLanguageSwitched);
};

export const removeLanguageSwitchHandlers = async (onLanguageSwitched) => {
  connection.off(VIRTUAL_CABIN_EVENTS.LanguageSwitched, onLanguageSwitched);
};

export const setupVirtualCabinEventHandlers = (onTimerStarted, onTimerStopped, onMicrophoneToggled) => {
  if (onTimerStarted) {
    connection.on(VIRTUAL_CABIN_EVENTS.TimerStarted, onTimerStarted);
  }

  if (onTimerStopped) {
    connection.on(VIRTUAL_CABIN_EVENTS.TimerStopped, onTimerStopped);
  }

  if (onMicrophoneToggled) {
    connection.on(VIRTUAL_CABIN_EVENTS.MicrophoneToggled, onMicrophoneToggled);
  }
};

export const removeVirtualCabinEventHandlers = (onTimerStarted, onTimerStopped, onMicrophoneToggled) => {
  if (onTimerStarted) {
    connection.off(VIRTUAL_CABIN_EVENTS.TimerStarted, onTimerStarted);
  }

  if (onTimerStopped) {
    connection.off(VIRTUAL_CABIN_EVENTS.TimerStopped, onTimerStopped);
  }

  if (onMicrophoneToggled) {
    connection.off(VIRTUAL_CABIN_EVENTS.MicrophoneToggled, onMicrophoneToggled);
  }
};

export const setupSessionHandlers = (onSessionEnd, onSessionLiveRecordingDisabled, onSessionLiveRecordingEnabled) => {
  connection.on(SESSION_EVENTS.SessionClosed, onSessionEnd);
  connection.on(SESSION_EVENTS.SessionLiveRecordingEnabled, onSessionLiveRecordingEnabled);
  connection.on(SESSION_EVENTS.SessionLiveRecordingDisabled, onSessionLiveRecordingDisabled);
};

export const removeSessionHandlers = (onSessionEnd, onSessionLiveRecordingDisabled, onSessionLiveRecordingEnabled) => {
  connection.off(SESSION_EVENTS.SessionClosed, onSessionEnd);
  connection.off(SESSION_EVENTS.SessionLiveRecordingEnabled, onSessionLiveRecordingEnabled);
  connection.off(SESSION_EVENTS.SessionLiveRecordingDisabled, onSessionLiveRecordingDisabled);
};

export const sendEndSessionEvent = async (id) => {
  try {
    await connection.invoke(SESSION_EVENTS.CloseSession, id);
  } catch (e) {
    console.error("Error sending `Close Session` event: ", e);
  }
};

export const joinVirtualCabin = async (virtualCabinAlias) => {
  try {
    await connection.invoke(VIRTUAL_CABIN_EVENTS.JoinVirtualCabin, virtualCabinAlias);
    await connection.invoke(CHAT_EVENTS.JoinChat, virtualCabinAlias);
  } catch (e) {
    console.error("Error joining Virtual Cabin: ", e);
  }
};

export const leaveVirtualCabin = async (virtualCabinAlias) => {
  try {
    await connection.invoke(VIRTUAL_CABIN_EVENTS.LeaveVirtualCabin, virtualCabinAlias);
    await connection.invoke(CHAT_EVENTS.LeaveChat, virtualCabinAlias);
  } catch (e) {
    console.error("Error leaving Virtual Cabin: ", e);
  }
};

export const joinOrganization = async (organizationAlias) => {
  try {
    await connection.invoke(VIRTUAL_CABIN_EVENTS.JoinOrganization, organizationAlias);
  } catch (e) {
    console.error("Error on joining organization: ", e);
  }
};

export const leaveOrganization = async (organizationAlias) => {
  try {
    await connection.invoke(VIRTUAL_CABIN_EVENTS.LeaveOrganization, organizationAlias);
  } catch (e) {
    console.error("Error on leaving organization: ", e);
  }
};

export const sendChatMessage = async (message) => {
  try {
    await connection.invoke(CHAT_EVENTS.SendChatMessage, message);
  } catch (e) {
    console.error("Error sending chat message: ", e);
  }
};

export const chatRequest = async (chatRequestLink) => {
  try {
    const response = await axiosWrapper({
      url: chatRequestLink.href,
      method: chatRequestLink.method,
      headers: chatRequestLink.headers,
    });

    if (response && response.data) {
      return response;
    }

    return null;
  } catch (e) {
    console.error(`Error on chat request: ${chatRequestLink.rel}`, e);
  }
};

export const switchLanguage = async (cabin, currentSession) => {
  try {
    const request = cabin.links.find((item) => item.rel === "switch_language");

    if (request) {
      const { href, method, headers } = request;

      const response = await axiosWrapper({
        url: href,
        method,
        headers,
      });

      if (response && response.status === 200) {
        await connection.invoke(VIRTUAL_CABIN_EVENTS.LanguageSwitch, currentSession.alias, cabin.alias);
      }
    }
  } catch (e) {
    console.error("Error on switching language: ", e);
  }
};

export const startTimer = async (virtualCabinId, startTimerLink) => {
  try {
    const result = await axiosWrapper({
      url: startTimerLink.href,
      method: startTimerLink.method,
      headers: startTimerLink.headers,
    });

    if (result && result.data) {
      await connection.invoke(VIRTUAL_CABIN_EVENTS.StartTimer, virtualCabinId, result.data);
    }
  } catch (e) {
    console.error("Error on starting timer: ", e);
  }
};

export const stopTimer = async (virtualCabinId, startTimerLink) => {
  try {
    await axiosWrapper({
      url: startTimerLink.href,
      method: startTimerLink.method,
      headers: startTimerLink.headers,
    });

    await connection.invoke(VIRTUAL_CABIN_EVENTS.StopTimer, virtualCabinId);
  } catch (e) {
    console.error("Error on stopping timer: ", e);
  }
};

export const enablePanic = async (organizationAlias, payload) => {
  try {
    await connection.invoke(VIRTUAL_CABIN_EVENTS.EnablePanic, organizationAlias, payload);
  } catch (e) {
    console.error("Error on enabling panic: ", e);
  }
};

export const disablePanic = async (organizationAlias, payload) => {
  try {
    await connection.invoke(VIRTUAL_CABIN_EVENTS.DisablePanic, organizationAlias, payload);
  } catch (e) {
    console.error("Error on disabling panic: ", e);
  }
};

export const toggleMicrophone = async (virtualCabinAlias, userAlias) => {
  try {
    await connection.invoke(VIRTUAL_CABIN_EVENTS.ToggleMicrophone, virtualCabinAlias, userAlias);
  } catch (e) {
    console.error("Error on toggling microphone: ", e);
  }
};

export const enableSessionRecording = async (session) => {
  try {
    await connection.invoke(SESSION_EVENTS.EnableSessionLiveRecording, session.alias);
  } catch (e) {
    console.error("Error on enable session recording: ", e);
  }
};

export const disableSessionRecording = async (session) => {
  try {
    await connection.invoke(SESSION_EVENTS.DisableSessionLiveRecording, session.alias);
  } catch (e) {
    console.error("Error on disable session recording:", e);
  }
};
