import { SET_LANGUAGES } from "state/actions/languages";

const initialState = null;

const languages = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGES:
      return action.payload;
    default:
      return state;
  }
};

export default languages;
