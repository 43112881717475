import { Suspense, lazy } from "react";

import Loading from "./Loading";

const ProfileLazy = lazy(() => import("./Profile"));

const Profile = () => (
  <Suspense fallback={<Loading />}>
    <ProfileLazy />
  </Suspense>
);

export default Profile;
