import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import axiosWrapper from "shared/services/axiosWrapper";
import { isSysAdminOrAdminOrSupervisor } from "shared/utils";
import { setLanguages } from "state/actions/languages";
import { setRoles } from "state/actions/roles";

const GeneralProtectedRequests = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const fetchLanguages = useCallback(async () => {
    const request = user.links.find((item) => item.rel === "get_data_languages");

    if (request) {
      const { href, method, headers } = request;

      try {
        const response = await axiosWrapper({
          url: href,
          method,
          headers,
        });

        dispatch(setLanguages(response.data));
      } catch (e) {
        console.error("Error on get languages: ", e);
      }
    }
  }, [user, dispatch]);

  const fetchRoles = useCallback(async () => {
    const request = user.links.find((item) => item.rel === "get_data_userroles");

    if (request) {
      const { href, method, headers } = request;

      try {
        const response = await axiosWrapper({
          url: href,
          method,
          headers,
        });

        dispatch(setRoles(response.data));
      } catch (e) {
        console.error("Error on get roles: ", e);
      }
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (user) {
      fetchLanguages();

      if (isSysAdminOrAdminOrSupervisor(user)) {
        fetchRoles();
      }
    }
  }, [user, fetchLanguages, fetchRoles]);

  return null;
};

export default GeneralProtectedRequests;
