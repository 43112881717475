import { Suspense, lazy } from "react";

import Loading from "./Loading";

const ExteranlInterpreterDashboardLazy = lazy(() => import("./joinForm"));

const ExternalInterpreterDashboard = () => (
  <Suspense fallback={<Loading />}>
    <ExteranlInterpreterDashboardLazy />
  </Suspense>
);

export default ExternalInterpreterDashboard;
