export const SYSADMIN = 1;
export const ADMIN = 2;
export const INTERPRETER = 3;
export const SUPERVISOR = 4;
export const BASIC_USER = 5;
export const EXTERNALINTERPRETER = 8;

export const BROADCAST_KICK = "Kick_Event";
export const BROADCAST_KICK_ADMIN_HANG_UP = "Kick_Admin_Hang_up";
export const CHAT_MESSAGE = "Chat_Message";
export const RECORDING_STATE = "Recording_State";

export const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;
export const getLoginURL = () => `${API_BASE_URL}/account/login`;
export const getRegisterURL = () => `${API_BASE_URL}/account/register`;
export const getForgotPasswordURL = () => `${API_BASE_URL}/account/forgotpassword`;
export const getInviteConfirmationURL = () => `${API_BASE_URL}/invite/validate`;
export const getResetPasswordAnonymousURL = () => `${API_BASE_URL}/account/resetpasswordanonymous`;
export const getHubURL = () => `${API_BASE_URL}/hub`;
export const getInterpreterDetailsURL = (alias) => `${API_BASE_URL}/interpreters/${alias}`;
export const getValidateSpeakersURL = () => `${API_BASE_URL}/speakers/validate`;
export const getValidateExternalInterpreterURL = () => `${API_BASE_URL}/interpreters/validate`;

export const APP_BASE_URL = "";
export const APP_HOME_URL = `${APP_BASE_URL}/`;
export const APP_LOGIN_URL = `${APP_BASE_URL}/login`;
export const APP_REGISTER_URL = `${APP_BASE_URL}/register`;
export const APP_FORGOT_PASSWORD_URL = `${APP_BASE_URL}/forgotpassword`;
export const APP_MANAGE_CONFERENCES_URL = `${APP_BASE_URL}/conferences`;
export const APP_PENDING_REQUESTS_URL = `${APP_BASE_URL}/requests`;
export const APP_ORGANIZATIONS_URL = `${APP_BASE_URL}/organizations`;
export const APP_USERS_LIST_URL = `${APP_BASE_URL}/users`;
export const APP_INTERPRETERS_LIST_URL = `${APP_BASE_URL}/interpreters`;
export const APP_INVITE_URL = `${APP_BASE_URL}/invite`;
export const APP_SYSTEM_USAGE_URL = `${APP_BASE_URL}/system-usage`;
export const APP_INVITE_CONFIRMATION_URL = `${APP_BASE_URL}/invite-confirmation`;
export const APP_SPEAKER_DASHBOARD_URL = `${APP_BASE_URL}/speaker-dashboard`;
export const APP_EXTERNALINTERPRETER_DASHBOARD_URL = `${APP_BASE_URL}/interpreter-dashboard`;
export const APP_ASSIGNED_SESSIONS_URL = `${APP_BASE_URL}/sessions`;
export const APP_PROFILE_URL = `${APP_BASE_URL}/profile`;
export const APP_UNAUTHENTICATED_URL = `${APP_BASE_URL}/unauthenticated`;
export const APP_FORBIDDEN_URL = `${APP_BASE_URL}/forbidden`;

export const LANGUAGE_KEY = "__conf-management-language__";
export const USER_KEY = "__user__";
export const CONFERENCE_TOKEN_KEY = "__conf-management-token__";

export const SUPERVISOR_APPROVED_MESSAGE = "Request already approved";
export const SUPERVISOR_REJECTED_MESSAGE = "Request already rejected";
export const PAGINATION_DEFAULT_PAGE_SIZE = 10;
export const ALLOWED_FILE_EXTENSIONS_FOR_PROFILE = ["pdf", "doc", "docx", "jpg", "jpeg"];

export const CHAT_EVENTS = {
  JoinChat: "JoinChat",
  LeaveChat: "LeaveChat",
  SendChatMessage: "SendChatMessage",
  ReceiveMessage: "ReceiveMessage",
};

export const SESSION_EVENTS = {
  JoinSession: "JoinSession",
  LeaveSession: "LeaveSession",
  CloseSession: "CloseSession",
  SessionClosed: "SessionClosed",
  SessionLiveRecordingEnabled: "SessionLiveRecordingEnabled",
  SessionLiveRecordingDisabled: "SessionLiveRecordingDisabled",
  EnableSessionLiveRecording: "EnableSessionLiveRecording",
  DisableSessionLiveRecording: "DisableSessionLiveRecording",
};

export const VIRTUAL_CABIN_EVENTS = {
  JoinVirtualCabin: "JoinVirtualCabin",
  LeaveVirtualCabin: "LeaveVirtualCabin",
  JoinOrganization: "JoinOrganization",
  LeaveOrganization: "LeaveOrganization",
  StartTimer: "StartTimer",
  TimerStarted: "TimerStarted",
  StopTimer: "StopTimer",
  TimerStopped: "TimerStopped",
  LanguageSwitch: "LanguageSwitch",
  LanguageSwitched: "LanguageSwitched",
  ToggleMicrophone: "ToggleMicrophone",
  MicrophoneToggled: "MicrophoneToggled",
  EnablePanic: "EnablePanic",
  PanicEnabled: "PanicEnabled",
  DisablePanic: "DisablePanic",
  PanicDisabled: "PanicDisabled",
};
