import { SET_ROLES } from "state/actions/roles";

const initialState = null;

const roles = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROLES:
      return action.payload;
    default:
      return state;
  }
};

export default roles;
