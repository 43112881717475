import axios from "axios";

import { CONFERENCE_TOKEN_KEY, APP_LOGIN_URL, APP_FORBIDDEN_URL, APP_UNAUTHENTICATED_URL } from "shared/constants";

const axiosWrapper = axios.create();

axiosWrapper.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem(CONFERENCE_TOKEN_KEY);

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosWrapper.interceptors.response.use(
  (response) => response,
  (error) => {
    const { pathname } = window.location;

    if (error.response.status === 401 && pathname !== APP_LOGIN_URL) {
      window.location.replace(APP_UNAUTHENTICATED_URL);
    }

    if (error.response.status === 403 && pathname !== APP_FORBIDDEN_URL) {
      window.location.replace(APP_FORBIDDEN_URL);
    }

    return Promise.reject(error);
  }
);

export default axiosWrapper;
